.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.my-button{
  background-color:orange;
  color:red;
}

:where([class*="css-dev-only-do-not-override-"]).ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0;
}


.sign-in-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh;
  padding: 20px;
  background: '#f8f9fa';
}

.slider {
  width: 65%;
  margin: 20px;
  align-items: center;
  justify-content: center;
}

.signin {
  width: 35%;
  text-align: center;
  padding-top: 50px;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CSS */
.signin .center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.signin .center-content form {
  width: 60%; 
  max-width: 300px; 
}

.signin .center-content form input {
  width: 100%; 
}

.signin .center-content .ant-btn {
  width: 100%; 
  max-width: 400px; 
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 20px;
  color: #0b2241; /* Set the desired color */
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: #0b2241; /* Set the same color as the normal state */
}

.ant-carousel .slick-prev {
  left: 40px;
  z-index: 2;
}

.ant-carousel .slick-next {
  right: 40px;
  z-index: 2;
}



.menu-text {
  margin-left: 4px; 
}

.sidebar-container{
  padding-top: 40px;

}

.filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


.filter-title h3 {
  margin: 0; 
}


.chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.chart-container {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 20px;
}

.moreInfo-title{
  display: flex;
  align-items: center;
}
.moreInfo-title h4 {
  margin: 0; 
}

.filter-title:hover {
  color: #1677ff; /* Replace with your desired color */
}

.moreInfo-title:hover {
  color:#1677ff; /* Replace with your desired color */
}


::-webkit-scrollbar {
  width: 8px; 
}

::-webkit-scrollbar-track {
  background: transparent; 
  
}

::-webkit-scrollbar-thumb {
  background: #888888; 
  border-radius: 8px; 
}

.moreinfo-tabpane {
  border-top: none;
  margin: 15px;
}


.moreinfo-tab { 
  border: 1px solid #ddd;
  display: flex;
  border-radius: 4px;
  
}

.moreinfo-tab .ant-tabs-nav-list {
  padding-left: 12px; /* Adjust the value as needed */
}

.moreinfo-tab .moreinfo-tabpane {
  height: 150px; 
  overflow-y: auto; 
}

:where(.css-dev-only-do-not-override-1k979oh).ant-tabs-left > .ant-tabs-content-holder,
:where(.css-dev-only-do-not-override-1k979oh).ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 1px solid transparent; /* Adjust the thickness of the border as needed */
}


/* Apply the style to h3 elements within the filter-title class */
.filter-title h3 {
  font-weight: normal;
}


/* Update your CSS to make fine adjustments */
.checkbox-label {
  display: flex;
  align-items: center; /* Center items vertically */
  
}

/* Add this to your CSS file or <style> tag */
.checkbox-label input {
  vertical-align: bottom; /* Vertically align the checkbox with text */
}

.checkbox-label label {
  vertical-align: bottom; /* Vertically align the label with the checkbox */
  margin-left: 5px; /* Add some space between the checkbox and label */
}

.add-filters-modal {
  padding: 15px;
}

.css-1ym0cft {
  z-index: 5000 !important;
}

.css-3cr1qg-suggestionsContainer {
  z-index: 5000 !important;
}

.ant-modal-wrap {
  z-index: 9999 !important;
}

:where(.css-1adbn6x).ant-space {
  display: inline; 
}


.chart-tabpane {
  border-top: none;
  margin: 15px;
  min-height: 1000px;
}


.chart-tab { 
  border: 1px solid #ddd;
  display: flex;
  border-radius: 4px;  
}

.chart-tab .ant-tabs-nav-list {
  padding-left: 12px; /* Adjust the value as needed */
}

.cs-message-input__content-editor-wrapper {
  background-color: hsla(0, 0%, 64.7%, 0.5) !important;
  
}

.cs-message__content {
  background-color: hsla(0, 0%, 64.7%, 0.5) !important; 
}

.cs-message-input__content-editor {
  background-color: hsla(0, 0%, 77%, 0) !important; 
}

.cs-message-input__content-editor-container {
  background-color: hsla(0, 0%, 64.7%, 0.5) !important; 
}

.cs-button--attachment {
  color: #0B2241 !important; 
}

.cs-button--send {
  color: #0B2241 !important; 
}

.cs-button:disabled {
  opacity: 1 !important;
}

.ps__thumb-y {
  background-color:hsla(0, 0%, 77%, 1) !important; 
}


.cs-message-input__content-editor,
.cs-message-input__content-editor * {
  font-style: inherit !important;
  background-color: transparent !important; 
  font-weight: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
}



.cs-message__html-content,
.cs-message__html-content * {
  font-style: inherit !important;
  background-color: transparent !important; 
  font-weight: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
}

.body {
  background-color: #888888;
  background: #888888;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.ant-carousel .slick-next::before {
  content: none;
}

