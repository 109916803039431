body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  body {
	/* Hide the scrollbar, but keep the functionality for modern webkit-based browsers */
	scrollbar-width: thin; /* for Firefox */
	scrollbar-color: transparent transparent; /* for Firefox */
	-ms-overflow-style: none; /* for Internet Explorer/Edge */
	scrollbar-face-color: transparent; /* for Internet Explorer/Edge */
  }
  
  body::-webkit-scrollbar {
	width: 0;
	height: 0;
	display: none;
  }
  
  body {
	/* Hide the scrollbar for Firefox */
	scrollbar-width: none;
  }
  
  
  
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
  
  .title-link {
	cursor: pointer;
  }

  
  /* Define the expanded state */
  .expanded {
	max-height: 1000px; /* Adjust the max height as needed */
	opacity: 1;
	animation: expandAnimation 0.9s ease-in-out forwards;
  }
  
  @keyframes expandAnimation {
	from {
	  max-height: 0;
	  opacity: 0;
	}
	to {
	  max-height: 1000px; /* Adjust the max height as needed */
	  opacity: 1;
	}
  }

  
  /* Define the collapsed state */
.collapsed {
	max-height: 0;
	opacity: 0;
	animation: collapseAnimation 0.9s ease-out forwards;
  }
  
  @keyframes collapseAnimation {
	from {
	  max-height: 1000px; /* Adjust the max height as needed */
	  opacity: 1;
	}
	to {
	  max-height: 0;
	  opacity: 0;
	}
  }
  